import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const loadInitialTemplateData = () => {
    const storedTemplateData = sessionStorage.getItem('templateData');
    return storedTemplateData ? JSON.parse(storedTemplateData) : [];
}

export const fetchTemplateData = createAsyncThunk(
    'templateData/fetchTemplateData',
    async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/templates`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('token')}`
            }

        });
        return response.json();
    }
);

const templateDataSlice = createSlice({
    name: "templateData",
    initialState: {
        isLoading: false,
        templateData: loadInitialTemplateData(),
        isError: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTemplateData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTemplateData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.templateData = action.payload;

            // Save the fetched template data to sessionStorage
            sessionStorage.setItem('templateData', JSON.stringify(action.payload));
        });
        builder.addCase(fetchTemplateData.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            console.log("Error: ", action.payload);
        });
    }
});

export default templateDataSlice.reducer;

