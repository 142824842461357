import { calculateRemainingDays } from "./calculateDays";

export const getCurrentSubscriptionPlaned = (userPlanes) => {
  const currentActivePlane = [];
  userPlanes.forEach((plane) => {
    if (
      calculateRemainingDays(plane.subscription_end_date) > 1 &&
      (plane?.subscription_status === "active" ||
        plane?.subscription_status === "canceled" ||
        plane?.subscription_status === "trialing")
    ) {
      currentActivePlane.push(plane.subscription_plan_type);
    }
  });
  return currentActivePlane;
};
